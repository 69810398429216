import React from "react"
import AboutImage from "../components/about-image"
import Layout from '../components/layout'
import SEO from "../components/seo"
import Signature from "../components/signature"
import styles from './about.module.scss'
import {Link} from 'gatsby'

const AboutPage = () => {
  return (
    <Layout>
      <SEO title="About" keyworkds={[ `raksha`, `about me`, `reflect`, `simple things`, `bullet journal`]} />
      <div className={styles.container}>
        <div className={styles.column}>
          <AboutImage/>
        </div>
        <div className={styles.column}>
          <p className={styles.headline}>Hi, I’m Raksha,</p>
          <p>A bullet journaller, content creator and idle thinker.</p>
          <p>In 2020 during the global pandemic, I took a career break and decided to share my mental wellness journey. This was the beginning of Reflect with Raksha.</p>
          <p>I was grateful for the beautiful life that I was living, but I wanted to do more to contribute to the wider world and feel a sense of purpose and fulfilment.</p>
          <p>My bullet journal helps me slow down, reflect and live a purposeful life so I decided to share it with the rest of the world. Along with ideas and suggestions for anyone intersted in looking after their own wellbeing.</p>
          <p>It was scary and exciting sharing so much of myself on YouTube but I loved it immediately.
            I love engaging with the wonderful community, sharing ideas and having the opportunity to help others find meaning and purpose.
            I feel warm and fuzzy every time I receive a message from someone saying I’ve helped to improve their way of life.</p>
          <p>Aside from making videos and bullet journalling, my passions include reading, yoga, watching films, hiking, playing board games, hanging out with friends and family and messing around with my 2 young children.</p>
          <p>Please join on me on this exciting journey. You can find me on YouTube, where I post a video every week. Follow me on Instagram, check out my blog and <Link to="/subscribe">sign up to my weekly newsletter</Link>.</p>
          <p>Look forward to hearing from you!</p>
          <p>With love,</p>
          <Signature/>
        </div>
      </div>
    </Layout>  
  )
}

export default AboutPage;